import React, { Component, PropTypes } from 'react'
import {
  KBLoadingContainer,
  KBPopover,
  KBTableWithoutData,
  KBAvatar
} from 'components'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import { api } from 'app/services'
import { bindActionCreators } from 'redux'
import { KBPopoverConfirm } from 'components/tools'
import { connect } from 'react-redux'
import {
  usersActions,
  deskActions,
  orgUserActions,
  apiCallFailure
} from 'app/actions'
import * as selectors from 'app/selectors'
import { MEMBER_GENDER_OBJ } from 'app/constants'
import { canAccessLocation, canRechargePoint, onlyHasWeChatAdmin } from 'app/reducers/role'
import {
  checkNull,
  formatMinuteSecondEN,
  getServiceErrorMessage
} from 'utils/kbUtil'
import MembershipResourceGrantForm from './MembershipResourceGrantForm'
import { getListFirstValue } from 'utils/kbUtil'
import OrganizationMemberForm from '../organization/OrganizationMemberForm'
import _ from 'lodash'
import OrgMemberDeskForm from '../organization/OrgMemberDeskForm'
import OrgBlock from '../organization/OrgBlock'
import MemberForm from '../spaces/MemberForm'
import config from 'app/config'
import { getUserInfoUrl } from 'utils/kbUrl'
import { PopoverShowPicture } from 'components/views'

const SPACE_MNG_TABS = [
  {
    name: '空间权限',
    value: 'space_role'
  },
  {
    name: '分店权限',
    value: 'location_role'
  },
  {
    name: '上级员工',
    value: 'superior'
  },
  {
    name: '下级员工',
    value: 'subordinate'
  }
]

const MEMBER_CARD_STATUS = {
  true: {
    status: '已绑定',
    className: 'receipt-finish'
  },
  false: {
    status: '未绑定',
    className: 'service-canceled'
  }
}

var MembersMessage = React.createClass({
  getInitialState() {
    const { member } = this.props
    return {
      loading: true,
      userCreditsMessage: {},
      membershipMessage: {},
      pointMessage: {},
      cardStatus: member.ic_card_exist,
      //member && member.ic_card_exist,
      currentTab: 'space_role'
    }
  },

  componentDidMount() {
    this.mounted = true
    this._loadMembershipData()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadMembershipData() {
    const { user } = this.props

    api
      .getMembershipUserMembership(
        user.sales_customer && user.sales_customer.id
      )
      .then(({ json }) => {
        this.getMemberships(json)
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  getMemberships(json) {
    let userCreditsMessage = {}
    let membershipMessage = {}
    let pointMessage = {}

    const { credits, memberships, points } = json
    if (credits && credits.length > 0) {
      credits.map(credit => {
        if (credit.resource_type == 'booking') {
          userCreditsMessage.booking = credit
        }
        if (credit.resource_type == 'desk') {
          userCreditsMessage.desk = credit
        }
        if (credit.resource_type == 'point') {
          userCreditsMessage.point = credit
        }
        if (credit.resource_type == 'printing') {
          userCreditsMessage.printing = credit
        }
      })
    }
    if (memberships && memberships.length > 0) {
      memberships.map(membership => {
        if (membership.customer_type == 'user') {
          membershipMessage = membership
        }
      })
    }
    if (points && points.length > 0) {
      points.map(point => {
        if (point.customer_type == 'user') {
          pointMessage = point
        }
      })
    }
    this.setState({
      userCreditsMessage,
      membershipMessage,
      pointMessage
    })
  },

  updateMemberDesk(member, type) {
    const { org, loc_id } = this.props
    KBPopover.show(
      <OrgMemberDeskForm
        member={member}
        org={org}
        type={type}
        loc_id={loc_id}
      />,
      {
        closeOnOutsideClick: false
      }
    )
  },

  clearMemberDesk(m) {
    const { org, deleteDeskActions, updateUserActions } = this.props

    KBPopoverConfirm({
      name: '取消入驻',
      context: '是否取消当前员工工位!',
      callback: () => {
        return api.putFreeDeskOrgMember(org.id, m.id).then(
          json => {
            const {
              json: {
                entities: { users }
              }
            } = json
            const values = Object.values(users)[0]
            let entry = {
              [m.id]: {
                ...values,
                ...values.user,
                id: values.user.id
              }
            }

            entry[m.id].desks = entry[m.id].desks.map(json => {
              json.user = values.user
              return json
            })

            json.json.entities.users = entry
            json.json.entities.orgUsers = entry
            updateUserActions.success(json)
            deleteDeskActions.success({}, { key: 'desks', id: m.desk_id })
            KBPopover.close()
          },
          errors => {
            updateUserActions.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  rechargeable() {
    const { loc_id, user } = this.props
    const { pointMessage } = this.state
    KBPopover.show(
      <MembershipResourceGrantForm
        loc_id={loc_id}
        member_id={user.sales_customer.id}
        pointMessage={pointMessage}
        callback={this._loadMembershipData}
      />
    )
  },

  deleteMember(member, org_id) {
    let { routerActions } = this.props

    KBPopoverConfirm({
      name: '删除会员',
      context: `您确定要删除会员${member.name || member.id}吗?`,
      callback: () => {
        return api.deleteOrgnizationMember(member.id, org_id).then(
          json => {
            this.props.deleteUserActions.success(json, {
              key: 'users',
              id: member.id
            })
            this.props.deleteOrgUserActions.success(json, {
              key: 'orgUsers',
              id: member.id
            })
            KBPopover.close()
            routerActions.goBack()
          },
          errors => {
            this.props.deleteUserActions.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  deleteMemberCard(member) {
    const loc_id =
      member.locations && member.locations[0] && member.locations[0].id

    loc_id &&
      KBPopoverConfirm({
        name: '删除门禁卡',
        context: `您确定要删除会员${member.name || member.id}的门禁卡吗?`,
        callback: () => {
          return api
            .updateMemberIcCard(loc_id, member.id)
            .then(
              json => {
                this.setState({
                  cardStatus: false
                })
              },
              error => {
                const errorMessage = getServiceErrorMessage(error)
                errorMessage &&
                  this.props.apiCallFailureActions({
                    status: 'error',
                    message: errorMessage
                  })
              }
            )
            .finally(() => {
              KBPopover.close()
            })
        }
      })
  },

  editSpaceMember(member) {
    const {
      org,
      location,
      departments,
      members,
      currentDepartments,
      refreshData
    } = this.props
    let m = Object.assign({}, member, {
      parent_id: (member.parent && member.parent.id) || '',
      department_ids:
        (member.department_ids && member.department_ids.join(',')) || ''
    })

    KBPopover.show(
      <MemberForm
        member={m}
        type="update"
        initialValues={m}
        parent={[m.parent]}
        currentDepartments={currentDepartments}
        departments={departments}
        members={members}
      />
    )
  },

  editMember(member) {
    const { org_id, loc_id, memberType } = this.props

    if (member.space_member && !memberType) {
      return this.editSpaceMember(member)
    }

    let m = Object.assign({}, member, {
      birth_year: checkNull(member.birth_year),
      birth_month: checkNull(member.birth_month),
      birth_day: checkNull(member.birth_day),
      skills: member.skill_list && member.skill_list.join(','),
      title: (member.organization && member.organization.title) || ''
    })

    KBPopover.show(
      <OrganizationMemberForm
        defaultSkills={
          member.skill_list &&
          member.skill_list.map(json => {
            return { id: json, name: json }
          })
        }
        member={m}
        type="update"
        loc_id={loc_id}
        initialValues={m}
      />
    )
  },

  changeTap(value) {
    this.setState({
      currentTab: value
    })
  },

  getGroupedRoles(roles) {
    let newRoles = {}
    roles &&
      roles.map(json => {
        let tempIdKeys = Object.keys(newRoles)
        let s_id = json.location_id || 'no_loc'
        if (tempIdKeys.includes(String(s_id))) {
          newRoles[s_id].push(json)
        } else {
          newRoles[s_id] = []
          newRoles[s_id].push(json)
        }
      })

    return newRoles
  },

  getRandom(id) {
    let random = id % 10

    if (random < 10) {
      random = '0' + random
    } else if (random > 12) {
      random = 11
    }

    return random
  },

  clickPhoto(url) {
    KBPopover.show(<PopoverShowPicture photoUrl={url} />)
  },

  render() {
    const {
      member,
      loc_id,
      member_id,
      area_name,
      lockEvents,
      virtual_currency_name,
      locationsObj,
      isManager,
      isRechargePoint
    } = this.props
    const {
      currentTab,
      pointMessage,
      loading,
      cardStatus,
      icCards
    } = this.state
    let isPropsMember = member

    let memberBirth = isPropsMember.birth_year
      ? `${isPropsMember.birth_year}-${isPropsMember.birth_month}-${isPropsMember.birth_day}`
      : '暂无'
    const isSettle =
      isPropsMember.desks &&
      isPropsMember.desks.map(j => j.serial_number).join(',')
    const isSpaceMember = member.space_member

    const organizations = member.organizations

    const allRoles = member.space_roles || []
    const spaceRoles = allRoles.filter(role => {
      return !role.location_id
    })
    const locationRoles = allRoles.filter(role => {
      return role.location_id
    })
    const groupedLocRoles = this.getGroupedRoles(locationRoles)
    const superiors = Object.assign([], member.parent ? [member.parent] : [])
    const subordinates = member.childrens || []

    return (
      <section className="nav-section-container-multi f-right">
        <div className="multi-section-each">
          <header className="member-header">
            <h2 className="header-title f-left" style={{ fontSize: '22px' }}>
              会员信息
            </h2>
            {isManager ? (
              <div>
                {/**
                  isSettle ?
                    (
                      <button className='bordered-btn' onClick={ (e) => {this.updateMemberDesk(isPropsMember, 'update');e.stopPropagation();} }>修改工位</button>
                    ) :
                    (
                      <button className='bordered-btn' onClick={ (e) => {this.updateMemberDesk(isPropsMember, 'create');e.stopPropagation();} }>入驻</button>
                    )
                */}
                <button
                  className="bordered-btn"
                  onClick={e => {
                    this.editMember(isPropsMember)
                    e.stopPropagation()
                  }}
                >
                  编辑员工
                </button>
                {organizations.length === 1 ? (
                  <button
                    className="bordered-btn"
                    onClick={this.deleteMember.bind(
                      null,
                      isPropsMember,
                      organizations[0].id
                    )}
                  >
                    删除员工
                  </button>
                ) : null}
              </div>
            ) : null}
          </header>
          <KBLoadingContainer loading={loading}>
            <div
              className="nav-section-content-container"
              style={{ paddingBottom: 30 }}
            >
              <div
                className="kb-form-container"
                style={{ padding: 0, paddingTop: 10, position: 'relative' }}
              >
                {/* <div
                  className="kb-form-group"
                  style={{
                    position: 'absolute',
                    left: '570px',
                    float: 'unset',
                    height: 'auto'
                  }}
                >
                  <label style={{ display: 'block' }}>Face++ 照片</label>
                  {member.face_photo_uploaded ? (
                    <div className="group-content">
                        <span>已上传</span>
                      </div>
                  ) : (
                      <div className="group-content">
                        <span>未上传</span>
                      </div>
                    )}
                </div> */}
                <div className="kb-row kb-form-48">
                  <div className="kb-form-group kb-div-c39">
                    <label>姓名</label>
                    <div className="group-content">
                      <span>{isPropsMember.name}</span>
                    </div>
                  </div>
                  <div className="kb-form-group kb-div-c39">
                    <label>Face++ 照片</label>
                    <div className="group-content">
                      <span>{member.face_photo_uploaded ? '已上传' : '未上传'}</span>
                    </div>
                  </div>
                  {/* <div className="kb-form-group kb-div-173">
                    <label className="label-rt">性别</label>
                    <div className="group-content">
                      <span>
                        {MEMBER_GENDER_OBJ[isPropsMember.gender] || '-'}
                      </span>
                    </div>
                  </div> */}
                </div>
                <div className="kb-row kb-form-48">
                  {/* <div className="kb-form-group kb-div-c39">
                    <label>生日</label>
                    <div className="group-content">
                      <span>{memberBirth}</span>
                    </div>
                  </div> */}
                  <div className="kb-form-group kb-div-173">
                    <label className="label-rt">电话</label>
                    <div className="group-content">
                      <span>{isPropsMember.phone_num || '-'}</span>
                    </div>
                  </div>
                  <div className="kb-form-group kb-div-173">
                    <label className="label-rt">邮箱</label>
                    <div className="group-content">
                      <span>
                        {isPropsMember.email ||
                          isPropsMember.unconfirmed_email ||
                          '-'}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="kb-row kb-form-48">
                  <div className="kb-form-group kb-div-c39">
                    <label>微信号</label>
                    <div className="group-content">
                      <span>{isPropsMember.wechat || '-'}</span>
                    </div>
                  </div>
                  <div className="kb-form-group kb-div-173">
                    <label className="label-rt">邮箱</label>
                    <div className="group-content">
                      <span>
                        {isPropsMember.email ||
                          isPropsMember.unconfirmed_email ||
                          '-'}
                      </span>
                    </div>
                  </div>
                </div> */}
                <div className="kb-row kb-form-48">
                  <div className="kb-form-group kb-div-c39">
                    <label>门禁卡编号</label>
                    <div className="group-content">
                      <span>{isPropsMember.ic_card_id || '-'}</span>
                      {cardStatus ? (
                        <a
                          className="c-pointer"
                          style={{ color: '#2ea1f8', marginLeft: 10 }}
                          onClick={this.deleteMemberCard.bind(
                            null,
                            isPropsMember
                          )}
                        >
                          解除
                        </a>
                      ) : (
                          ''
                        )}
                    </div>
                  </div>
                  <div className="kb-form-group kb-div-173">
                    <label className="label-rt">工位</label>
                    <div className="group-content">
                      <span>
                        {area_name}&nbsp;{isSettle || '-'}&nbsp;
                      </span>
                    </div>
                  </div>
                </div>
                <div className="kb-row kb-form-48">
                  <div className="kb-form-group kb-div-c39">
                    <label>打印账户</label>
                    <div className="group-content">
                      <span>{isPropsMember.print_account_name || '-'}</span>
                    </div>
                  </div>
                  <div className="kb-form-group kb-div-173">
                    <label className="label-rt">所属分店</label>
                    <div className="group-content">
                      <span>
                        {(isPropsMember.locations &&
                          isPropsMember.locations
                            .map(loc => loc.name)
                            .join(', ')) ||
                          '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="kb-row kb-form-48">
                  <div className="kb-form-group kb-div-c39">
                    <label>备注</label>
                    <div className="group-content">
                      <span>{isPropsMember.notes || '-'}</span>
                    </div>
                  </div>
                  <div className="kb-form-group kb-div-173">
                    <label className="label-rt">黑名单</label>
                    <div className="group-content">
                      <span>
                        {(isPropsMember.blacklisted_orgs &&
                          isPropsMember.blacklisted_orgs.toString()) ||
                          '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="member-credits">
                <div className="credit-message">
                  <div className="vf-center">
                    <div className="star-icon">
                      <i className="iconfont icon-star2" />
                    </div>
                    <div className="point-con">
                      <Link
                        to={`${getUserInfoUrl(member_id)}/${getListFirstValue(
                          'point'
                        )}`}
                      >
                        <div className="point-num a-hover">
                          {(pointMessage && pointMessage.balance) || 0}&nbsp;
                          {virtual_currency_name}
                        </div>
                      </Link>
                      <div style={{ marginTop: 1 }}>
                        {virtual_currency_name}数量
                      </div>
                    </div>
                  </div>
                  {isRechargePoint ? (
                    <button
                      className="operation-btn"
                      onClick={this.rechargeable}
                    >
                      赠送
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </KBLoadingContainer>
        </div>

        {!isSpaceMember && organizations && organizations.length > 0 ? (
          <div className="multi-section-each">
            <header className="member-header">
              <h2 className="header-title f-left" style={{ fontSize: '22px' }}>
                所在公司信息
              </h2>
            </header>
            <div
              className="nav-section-content-container"
              style={{ paddingBottom: 30 }}
            >
              <div className="company-logo-all t-center">
                {organizations.map((org, index) => {
                  return (
                    <OrgBlock
                      location_id={loc_id}
                      org={org}
                      key={'allorg_' + index}
                      style={
                        (index + 1) % 3 === 0
                          ? { marginRight: 0 }
                          : (index + 1) % 4 === 0
                            ? { marginRight: 30 }
                            : {}
                      }
                      isGlobalOrg={true}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        ) : null}

        {isSpaceMember ? (
          <div className="kb-tip-warning">
            <div className="icon">
              <i className="iconfont icon-hint" />
            </div>
            <div className="msg">该会员是空间管理员工</div>
          </div>
        ) : null}

        {isSpaceMember ? (
          <div className="multi-section-each">
            <header>
              <ul
                className="nav-section-tab"
                style={{ padding: '10px 25px 0px 25px' }}
              >
                {SPACE_MNG_TABS.map((tab, index) => {
                  return (
                    <li
                      className={`${currentTab === tab.value ? 'nav-section-tab-active' : ''
                        }`}
                      onClick={this.changeTap.bind(null, tab.value)}
                    >
                      <a>{tab.name}</a>
                    </li>
                  )
                })}
              </ul>
            </header>
            <div
              className="nav-section-content-container"
              style={{ paddingBottom: 30 }}
            >
              {currentTab === 'space_role' ? (
                <div className="space-role-ctn">
                  <div className="ctn-title vf-center">
                    <div className="iconfont icon-img">
                      <i className="iconfont icon-company" />
                    </div>
                    <div className="content">
                      <div className="title">空间权限模块</div>
                      <div style={{ marginTop: 1 }}>
                        {spaceRoles.length
                          ? `该员工拥有${spaceRoles.length}个空间权限模块`
                          : '该员工暂无空间权限'}
                      </div>
                    </div>
                  </div>
                  {spaceRoles.length > 0 ? (
                    <div className="ctn-content">
                      {spaceRoles.map((role, index) => {
                        const roleName = role.role_name || ''
                        return (
                          <div className="space-role vf-center">
                            <div
                              className="kb-checked-style-circle"
                              style={{ marginRight: 14 }}
                            />
                            <div className="role-name">{roleName}</div>
                            {/* <div className="role-des">({ roleName })</div> */}
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                </div>
              ) : null}

              {currentTab === 'location_role' ? (
                <div className="">
                  {Object.values(groupedLocRoles).length > 0 &&
                    Object.values(groupedLocRoles).map((loc_roles, index) => {
                      let loc_id_of_roles =
                        loc_roles && loc_roles[0].location_id
                      let currentRoleLoc = locationsObj[loc_id_of_roles] || {}
                      let loc_img =
                        (currentRoleLoc.images && currentRoleLoc.images[0]) ||
                        `${config.qiniu_base_url
                        }static/locations/subbranch${this.getRandom(
                          loc_id_of_roles
                        )}.jpg`
                      let loc_name = currentRoleLoc.name || ''
                      return (
                        <div className="location-role-block vf-center">
                          <div className="loc-info vf-center">
                            <div
                              className="loc-img"
                              style={{ backgroundImage: `url(${loc_img})` }}
                            />
                            <div className="content">
                              <div className="title">{loc_name}</div>
                              <div style={{ marginTop: 1 }}>
                                {loc_roles.length}角色权限
                              </div>
                            </div>
                          </div>
                          <div className="roles">
                            {loc_roles.map((role, index) => {
                              let roleName = role.role_name || ''
                              return (
                                <div className="loc-role vf-center">
                                  <div
                                    className="kb-checked-style-circle"
                                    style={{ marginRight: 14 }}
                                  />
                                  <div className="role-name">{roleName}</div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                </div>
              ) : null}

              {currentTab === 'superior' ? (
                <div className="">
                  <table className="content-table table-align">
                    <thead>
                      <tr>
                        <th>姓名</th>
                        <th>电话</th>
                        <th>部门</th>
                        <th>职务</th>
                      </tr>
                    </thead>
                    <KBTableWithoutData
                      hasData={superiors.length > 0}
                      tableHeadNum="4"
                      tipMessage="暂无上级员工"
                    >
                      {superiors.map((user, index) => {
                        return (
                          <tr>
                            <td>
                              <div className="vf-center">
                                <KBAvatar
                                  user={user}
                                  imgStyle={{ marginRight: 10 }}
                                  style={{
                                    display: 'inline-block',
                                    marginRight: '10px'
                                  }}
                                  size={35}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between'
                                  }}
                                >
                                  <Link
                                    to={getUserInfoUrl(user.id)}
                                    className="color-link"
                                  >
                                    {user.name}
                                  </Link>
                                  <div
                                    style={{ fontSize: '12px', color: '#999' }}
                                  >
                                    {MEMBER_GENDER_OBJ[user.gender] || '暂无'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>{user.phone_num || '暂无'}</td>
                            <td>{user.title}</td>
                            <td>
                              {user.space_member
                                ? user.space_title
                                : user.title}
                            </td>
                          </tr>
                        )
                      })}
                    </KBTableWithoutData>
                  </table>
                </div>
              ) : null}

              {currentTab === 'subordinate' ? (
                <div className="">
                  <table className="content-table table-align">
                    <thead>
                      <tr>
                        <th>姓名</th>
                        <th>电话</th>
                        <th>部门</th>
                        <th>职务</th>
                      </tr>
                    </thead>
                    <KBTableWithoutData
                      hasData={subordinates.length > 0}
                      tableHeadNum="4"
                      tipMessage="暂无下级员工"
                    >
                      {subordinates.map((user, index) => {
                        return (
                          <tr>
                            <td>
                              <div className="vf-center">
                                <KBAvatar
                                  user={user}
                                  imgStyle={{ marginRight: 10 }}
                                  style={{
                                    display: 'inline-block',
                                    marginRight: '10px'
                                  }}
                                  size={35}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between'
                                  }}
                                >
                                  <Link
                                    to={getUserInfoUrl(user.id)}
                                    className="color-link"
                                  >
                                    {user.name}
                                  </Link>
                                  <div
                                    style={{ fontSize: '12px', color: '#999' }}
                                  >
                                    {MEMBER_GENDER_OBJ[user.gender] || '暂无'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>{user.phone_num || '暂无'}</td>
                            <td>{user.title}</td>
                            <td>
                              {user.space_member
                                ? user.space_title
                                : user.title}
                            </td>
                          </tr>
                        )
                      })}
                    </KBTableWithoutData>
                  </table>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { member, params, location } = props
  let memberType = (location && location.query && location.query.type) || ''
  let member_id = params.member_id
  let org_id = props.params.organization_id
  let loc_id = params.id
  const user = selectors.getUserById(state, member_id)
  const locationsObj = selectors.getSerializeLocationObj(state)

  let isManager = canAccessLocation(state.user, loc_id) ||  onlyHasWeChatAdmin(state.user)
  let isRechargePoint = canRechargePoint(state.user)||  onlyHasWeChatAdmin(state.user)
  let areas = selectors.getObjArea(state)
  let area =
    member.desks && member.desks.lenght
      ? areas[member.desks.find(value => value.id == member.desk_id).area_id]
        .name
      : ''
  let area_name = member.desk_id && area
  let newMember = member
  if (member.space_member) {
    newMember = Object.assign(
      {},
      selectors.getMemberById(state, member_id),
      member
    )
  }

  const org = selectors.getOrg(state, org_id)

  return {
    org,
    loc_id,
    org_id,
    user,
    member: newMember,
    member_id,
    area_name,
    locationsObj,
    isManager,
    isRechargePoint,
    memberType
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deleteUserActions: bindActionCreators(usersActions.delete, dispatch),
    deleteDeskActions: bindActionCreators(deskActions.delete, dispatch),
    deleteOrgUserActions: bindActionCreators(orgUserActions.delete, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    updateUserActions: bindActionCreators(usersActions.update, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersMessage)
