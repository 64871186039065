import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  getCitiesAction,
  getTradingAreaAction,
  locationActions,
  getProvincesAction,
  apiCallFailure,
  serializeLocationsActions,
} from 'app/actions'
import {
  KBInput,
  KBSelect,
  KBFormError,
  KBButton,
  KBTextarea,
  KBUserInput,
  KBPopover,
  KBPopoverTop,
  KBForm,
  KBMorePhotoUpload
} from 'components'
import {
  LOCATION_TYPES,
  CAN_BINDING_CARD,
  CAN_SET_TOUCH_PASSWORD,
  DISPLAY_BLUETOOTH_LOCK
} from 'app/constants'
import LocationProviderForm from './LocationProviderForm'
import { ADD_LOCATION } from 'app/constants/hint'
import * as selectors from 'app/selectors'
import { arrayToObject, groupLocationsByLocationGroup } from 'utils/kbUtil'

var LocationForm = React.createClass({
  getInitialState() {
    return {
      location_stores: [],
      location_members: this.props.location_members || [],
      trapeze: null
    }
  },
  createLocation(model) {
    const { successCallBack, space_members } = this.props
    model.public = 'false'
    const { manager_id, operator_ids } = model

    delete model.manager_id
    delete model.operator_ids
    let roles = []
    if (manager_id) {
      roles.push({
        role: 'location_manager',
        range: 'all',
        user_ids: [manager_id]
      })
    }

    if (operator_ids) {
      roles.push({
        role: 'location_operator',
        range: 'all',
        user_ids: operator_ids ? operator_ids.split(',') : []
      })
    }

    model.roles = roles

    return api.createLocation(model).then(json => {
      successCallBack && successCallBack(json)
      this.props.createLocationAction.success(json)
      // this.props.callBack();
      this.refreshSerializeLocations()
      KBPopover.close()
      this.props.callBack && this.props.callBack()
    })
  },
  refreshSerializeLocations() {
    api.getSerializeLocations({ per_page: 10000 }).then(
      json => {
        this.props.getSerializeLocationsActions.success(json)
      },
      errors => {
        this.props.getSerializeLocationsActions.failure(errors)
      }
    )
  },
  componentDidMount() {
    api.getCities().then(
      json => {
        this.props.getCitiesAction.success(json)
      },
      errors => {
        this.props.getCitiesAction.failure(errors)
      }
    )
    api.getProvinces().then(
      json => {
        this.props.getProvincesAction.success(json)
      },
      errors => {
        this.props.getProvincesAction.failure(errors)
      }
    )
    api.getLocationStores().then(json => {
      let location_stores_list = json.json
      let location_stores = []
      location_stores_list &&
        location_stores_list.map(stores => {
          location_stores.push({
            id: stores.id,
            name: stores.location_name
          })
        })
      this.setState({
        location_stores
      })
    })
  },

  getAddressTrapeze(city, address) {
    const {
      fields: { longitude, latitude }
    } = this.props
    return apiUtils
      ._apiGetTrapezeOfGaode(city, address)
      .then(({ json }) => {
        if (json.geocodes.length == 0) {
          return false
        }
        let geoCodes = json.geocodes[0]
        let trapeze = geoCodes.location
        let trapezeArray = trapeze.split(',')
        longitude.onChange(trapezeArray[0])
        latitude.onChange(trapezeArray[1])
        this.setState({
          trapeze,
          showLongitude: trapezeArray[0],
          showLatitude: trapezeArray[1]
        })
        return trapeze
      })
      .then(trapeze => {
        this.setState({ trapeze })
      })
  },

  addressCallback(e) {
    const {
      fields: { province_id, city_id, physical_address }
    } = this.props
    if (city_id.value == '') {
      return null
    } else if (city_id.value == '-1') {
      return null
    }
    let value = e.target.value
    physical_address.onChange(value)
    let cities = this.getCities(province_id)
    let cityName = cities[city_id.value].name
    value && this.getAddressTrapeze(cityName, value)
  },

  getCities(province_id) {
    const citiesArray = this.props.cities.filter(
      json => json.province_id == province_id.value
    )
    const cities = arrayToObject(citiesArray)
    return cities
  },

  addLocationProvider() {
    KBPopoverTop.show(<LocationProviderForm callBack={this.providerField} />)
  },

  providerField(provider) {
    const {
      fields: { location_providers }
    } = this.props
    location_providers.addField({
      info: provider.info,
      contact_name: provider.contact_name,
      contact_phone: provider.contact_phone,
      contact_email: provider.contact_email,
      account_bank: provider.account_bank,
      account_name: provider.account_name,
      account_number: provider.account_number
    })
  },

  render() {
    const {
      fields: {
        name,
        location_type,
        serial_number,
        prefix,
        city_id,
        location_providers,
        priority,
        location_store_id,
        province_id,
        images,
        display_bluetooth_lock,
        can_set_touch_password,
        latitude,
        can_binding_card,
        district_id,
        physical_address,
        size,
        telephone,
        image,
        description,
        manager_id,
        operator_ids,
        device_ids,
        price_range,
        location_group_id
      },
      error,
      submitting,
      handleSubmit,
      hideOther,
      devices,
      locationGroups,
      locations,
      group
    } = this.props
    const { trapeze, location_stores, showLongitude, showLatitude } = this.state
    const hint = ADD_LOCATION
    const citiesArray = this.props.cities.filter(
      json => json.province_id == province_id.value
    )
    const cities = arrayToObject(citiesArray)
    const provinces = this.props.provinces
    const city = cities && cities[city_id.value]
    const districts = (city && city.districts) || []
    let locationsList = group
      ? locationGroups.filter(item => item.id == group.id)
      : locationGroups.filter(item => item.locations.length > 0)
    // const group_id = group && group.id ? group.id : null
    // let groups = groupLocationsByLocationGroup(locations)
    // locationGroups && locationGroups.map((item, index) => {
    //   if (item.locations.length > 0) {
    //     locationsList.push(item)
    //   }
    // })

    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.createLocation)}
        fields={this.props.fields}
      >
        {hideOther ? (
          ''
        ) : (
          <header className="kb-form-header">
            <h2 className="header-title">添加新分店</h2>
            <i
              className="iconfont header-close icon-cancel"
              onClick={KBPopover.close}
            />
          </header>
        )}
        <div
          className="kb-form-container"
          ref={ref => {
            this.locationScroll = ref
          }}
        >
          <fieldset className="fieldset">
            <legend>基本信息</legend>
            <div className="kb-row kb-form-84">
              <KBInput
                className="kb-form-group kb-div-210"
                title="分店名称"
                isRequired={true}
                hint={hint.name}
                field={name}
                fistFoucs={true}
              />
              <KBInput
                className="kb-form-group kb-div-48"
                title="编号"
                hint={hint.number}
                field={serial_number}
                titleClass="label-rt"
              />
            </div>
            <div className="kb-row  kb-form-84">
              {group && group.id ? (
                <KBUserInput
                  title="所属项目"
                  placeholder="选择项目"
                  className="kb-form-group kb-div-210"
                  field={location_group_id}
                  disabled={true}
                  selectedUsers={locationsList.filter(lg => lg.id == group.id)}
                />
              ) : (
                <KBUserInput
                  title="所属项目"
                  placeholder="选择项目"
                  className="kb-form-group kb-div-210"
                  field={location_group_id}
                  multi={false}
                  users={locationsList}
                  selectedUsers={[]}
                />
              )}
              <KBInput
                className="kb-form-group kb-div-48"
                isRequired={true}
                title="项目前缀"
                hint="用于合同和订单编号的生成"
                field={prefix}
                titleClass="label-rt"
              />
            </div>
            <div className="kb-row">
              <KBSelect
                title="分店类型"
                defaultValue={'-1'}
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={location_type}
                arrayData={LOCATION_TYPES}
              />
            </div>
            <div className="kb-row kb-form-84">
              <div className="kb-form-66">
                <KBSelect
                  defaultValue={'-1'}
                  className="kb-form-group kb-div-48"
                  isRequired={true}
                  title="省份"
                  field={province_id}
                  arrayData={provinces}
                />
                <KBSelect
                  defaultValue={'-1'}
                  className="kb-form-group kb-div-48"
                  isRequiredR={true}
                  title="城市"
                  titleClass="label-rt"
                  field={city_id}
                  arrayData={cities}
                />
              </div>
              {districts.length != 0 ? (
                <KBSelect
                  defaultValue={'-1'}
                  className="kb-form-group kb-div-48"
                  isRequiredR={false}
                  titleClass="label-rt"
                  title="区"
                  field={district_id}
                  arrayData={districts}
                />
              ) : (
                <div></div>
              )}
            </div>
            <div className="kb-row" style={{ position: 'relative' }}>
              <div className="kb-form-group kb-form-c18 kb-form-alone">
                <label>
                  地址<span className="must-fill">*</span>
                </label>
                <div>
                  <textarea
                    className={`${
                      physical_address.touched && physical_address.error
                        ? 'kb-input-error'
                        : 'kb-input'
                    }`}
                    onChange={this.addressCallback}
                    style={{
                      height: 140,
                      paddingRight: 210,
                      paddingBottom: 20
                    }}
                  />
                  {physical_address.touched && physical_address.error ? (
                    <p className="lr-error f-left">{physical_address.error}</p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {trapeze ? (
                <img
                  src={`https://restapi.amap.com/v3/staticmap?markers=-1,http://oausa5585.bkt.clouddn.com/IconLOCAL@2x.png,0:${trapeze}&key=0974ffa7440cd3b5fb8b0c1ac782a957&zoom=17&scale=1`}
                  style={{
                    position: 'absolute',
                    right: 1,
                    top: 30,
                    height: 138,
                    width: 138
                  }}
                />
              ) : (
                ''
              )}
            </div>
            <div className="kb-row kb-form-66">
              <KBInput
                className="kb-form-group kb-form-210"
                hint={hint.tel}
                field={telephone}
                title="电话"
              />
              <KBInput
                className="kb-form-group kb-form-210"
                hint={hint.area}
                isRequired={true}
                inputClass="input-area"
                field={size}
                title="面积"
                titleClass="label-rt"
              />
            </div>
            <div className="kb-row kb-form-66">
              <KBInput
                className="kb-form-group kb-form-210"
                hint={'请输入价格范围的描述'}
                field={price_range}
                title="价格范围"
              />
              <KBInput
                className="kb-form-group kb-form-210"
                hint={'请输入数字，修改客户端的社区位置，数字越大排上面'}
                field={priority}
                title="排序位"
                titleClass="label-rt"
              />
            </div>
            <div className="kb-row">
              <KBSelect
                defaultValue=" "
                className="kb-form-group kb-div-c18 kb-form-alone"
                title="Location store"
                field={location_store_id}
                arrayData={location_stores}
              />
            </div>
            {location_type.value === 'self_office' ||
            location_type.value === 'sales_office' ? null : (
              <div>
                <div className="kb-row">
                  <KBUserInput
                    title="设备"
                    className="kb-form-group kb-form-c18 kb-form-alone"
                    field={device_ids}
                    placeholder="选择设备"
                    multi={true}
                    users={devices}
                  />
                </div>
                <div className="kb-row">
                  <KBSelect
                    defaultValue=" "
                    className="kb-form-group kb-div-c18 kb-form-alone"
                    title="自行绑卡"
                    field={can_binding_card}
                    arrayData={CAN_BINDING_CARD}
                  />
                </div>
                <div className="kb-row">
                  <KBSelect
                    defaultValue=" "
                    className="kb-form-group kb-div-c18 kb-form-alone"
                    title="触摸密码"
                    field={can_set_touch_password}
                    arrayData={CAN_SET_TOUCH_PASSWORD}
                  />
                </div>
                <div className="kb-row">
                  <KBSelect
                    defaultValue=" "
                    className="kb-form-group kb-div-c18 kb-form-alone"
                    title="蓝牙门禁"
                    field={display_bluetooth_lock}
                    arrayData={DISPLAY_BLUETOOTH_LOCK}
                  />
                </div>
                <div className="kb-row">
                  <KBTextarea
                    className="kb-form-group kb-form-c18 kb-form-alone"
                    hint={hint.intro}
                    field={description}
                    placeholder="输入简介"
                    title="简介"
                  />
                </div>
              </div>
            )}
          </fieldset>
          {location_type.value === 'self_office' ||
          location_type.value === 'sales_office' ? null : (
            <fieldset className="fieldset">
              <legend>收款账户信息</legend>
              {location_providers.length > 0 &&
                location_providers.map((provider, index) => {
                  return (
                    <div
                      className="location-provider"
                      style={{ marginBottom: 10 }}
                    >
                      <span
                        className="operate-provider delete-provider"
                        onClick={() => {
                          location_providers.removeField(index)
                        }}
                      >
                        <i className="iconfont icon-delete" />
                      </span>
                      <div className="provider-line">
                        <div>
                          <span>账户信息</span>
                          <span>{provider.info.value || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>账户开户行</span>
                          <span>{provider.account_bank.value || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>账户名</span>
                          <span>{provider.account_name.value || '暂无'}</span>
                        </div>
                        <div>
                          <span>账号</span>
                          <span>{provider.account_number.value || '暂无'}</span>
                        </div>
                      </div>
                      <div
                        className="provider-line"
                        style={{ marginBottom: 0 }}
                      >
                        <div>
                          <span>联系人</span>
                          <span>{provider.contact_name.value || '暂无'}</span>
                        </div>
                        <div>
                          <span>联系方式</span>
                          <span>{provider.contact_phone.value || '暂无'}</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              <button
                type="button"
                className="bordered-btn"
                style={{ marginLeft: 0, marginTop: 10 }}
                onClick={this.addLocationProvider}
              >
                添加收款账户信息
              </button>
            </fieldset>
          )}
          <fieldset className="fieldset">
            <legend>管理公司</legend>
            <div className="kb-row">
              <KBUserInput
                title="负责人"
                className="kb-form-group kb-form-c18 kb-form-alone"
                hint={hint.principal}
                isRequired={true}
                field={manager_id}
                multi={false}
                users={this.props.space_members}
                selectedUsers={this.state.location_members}
              />
            </div>
            <div className="kb-row">
              <KBUserInput
                title="员工"
                className="kb-form-group kb-form-c18 kb-form-alone"
                hint={hint.staff}
                isRequired={true}
                field={operator_ids}
                multi={true}
                users={this.props.space_members}
                selectedUsers={this.state.location_members}
              />
            </div>
          </fieldset>
          <div className="kb-row" style={{ textAlign: 'left' }}>
            <KBMorePhotoUpload
              uploadedText={`点击 x 继续上传`}
              hint="允许多张上传,格式为png或jpg,大小不大于20M"
              photoShow={true}
              sort={true}
              className="kb-form-group kb-form-alone"
              field={images}
              text="上传图片"
              accept=".png,.jpg"
              imgStyle={{ width: '232px', height: '134px', marginBottom: 10 }}
              isPublic
            />
          </div>
          {hideOther ? (
            <KBButton
              className="certain-btn"
              style={{ display: 'none' }}
              type="submit"
              submitting={submitting}
              id="locationFormButton"
            >
              确定
            </KBButton>
          ) : (
            <div className="kb-row fixed-form-btn">
              <span className="cancel-btn" onClick={KBPopover.close}>
                取消
              </span>
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={submitting}
                id="locationFormButton"
              >
                确定
              </KBButton>
            </div>
          )}
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const cities = selectors.getCitiesToArray(state)
  const provinces = selectors.getProvinces(state)
  const devices = selectors.getDevices(state)
  const locationGroups = selectors.getLocationGroups(state)
  let locations = selectors.getLocations(state)
  return {
    space_members: selectors.getSpaceUsers(state),
    cities,
    provinces,
    devices,
    locationGroups,
    locations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCitiesAction: bindActionCreators(getCitiesAction, dispatch),
    getProvincesAction: bindActionCreators(getProvincesAction, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    createLocationAction: bindActionCreators(locationActions.create, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    getSerializeLocationsActions: bindActionCreators(
      serializeLocationsActions.get,
      dispatch
    ),
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入分店名称'
  }

  // if(values.telephone&&valid.mobile(values.telephone)){
  //   errors.telephone = '请输入正确的手机号码'
  // }

  if (valid.required(values.size)) {
    errors.size = '请输入分店面积'
  }
  if (valid.required(values.prefix)) {
    errors.prefix = '请输入项目前缀'
  }
  if (valid.required(values.physical_address)) {
    errors.physical_address = '请输入分店地址'
  }

  if (valid.required(values.province_id)) {
    errors.province_id = '请选择省份'
  }

  if (valid.required(values.city_id)) {
    errors.city_id = '请选择城市'
  }

  // if(valid.required(values.district_id)){
  //   errors.district_id = '请选择城市区域'
  // }

  if (valid.required(values.operator_ids)) {
    errors.operator_ids = '请选择至少一位运营成员'
  }

  if (valid.required(values.manager_id)) {
    errors.manager_id = '请选择至少一位负责人'
  }

  if (valid.Float(values.longitude)) {
    errors.longitude = '请输入正确格式'
  }

  if (valid.Float(values.latitude)) {
    errors.latitude = '请输入正确格式'
  }

  if (values.priority && valid.Int(values.priority)) {
    errors.priority = '排序只能输入数字'
  }

  return errors
}

const formConfig = {
  form: 'LocationForm',
  fields: [
    'name',
    'location_type',
    'serial_number',
    'prefix',
    'city_id',
    'longitude',
    'latitude',
    'display_bluetooth_lock',
    'can_binding_card',
    'can_set_touch_password',
    'district_id',
    'province_id',
    'physical_address',
    'size',
    'priority',
    'location_store_id',
    'telephone',
    'image',
    'images',
    'description',
    'manager_id',
    'operator_ids',
    'public',
    'device_ids',
    'location_providers[].info',
    'location_providers[].contact_name',
    'location_providers[].contact_phone',
    'location_providers[].contact_email',
    'location_providers[].account_bank',
    'location_providers[].account_name',
    'location_providers[].account_number',
    'price_range',
    'location_group_id'
  ],
  validate: validate,
  touchOnBlur: false
}

LocationForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LocationForm)

export default LocationForm
