import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import { DateUtils } from 'react-day-picker'
import {
  usersActions,
  apiCallFailure,
  orgUserActions,
  successState
} from 'app/actions'
import {
  KBInput,
  KBDateInput,
  KBRadio,
  KBForm,
  KBSelect,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput,
  KBTextarea
} from 'components'
import {
  MEBMER_GENDERS_FORM,
  ORG_ROLES_FORM,
  ORG_MEMBER_SKILLS,
  DOCUMENT_TYPE
} from 'app/constants'
import { ADD_ORGANIZATION_MEMBER } from 'app/constants/hint'
import moment from 'moment'

const defaultYear = moment().get('year') - 1
const currentMoment = moment()

var OrganizationMemberForm = React.createClass({
  getInitialState() {
    return {
      phone_num_disabled: false,
      changeMemberState: true
    }
  },
  componentDidMount() {
    const {
      member,
      fields: { birth, birth_year, birth_month, birth_day }
    } = this.props
    if (birth_year.value && birth_day.value && birth_month.value) {
      birth.onChange(
        currentMoment
          .clone()
          .set({
            year: birth_year.value,
            month: birth_month.value - 1,
            date: birth_day.value
          })
      )
    }
    if (member) {
      this.setState({
        phone_num_disabled: member.phone_num_disabled
      })
    }
  },

  createOrganizationMemberForm(model) {
    const {
      loc_id,
      type,
      apiCallFailureActions,
      successAction,
      loadOrganizationInfo,
      org,
      member,
      callback
    } = this.props
    console.log('更新会员信息');
    const { phone_num_disabled, changeMemberState } = this.state
    if (model.birth) {
      model.birth_year = moment(model.birth).format('YYYY')
      model.birth_month = moment(model.birth).format('MM')
      model.birth_day = moment(model.birth).format('DD')
    }

    if(org && org.id) {
      // 是否可用办公券
      model.point_priviledge_enabled = JSON.parse(model.canUsePoint || false)
    }
    delete model.canUsePoint

    if (type === 'update') {
      const {
        member,
        updateUsersAction,
        fields: { role },
        reLoad
      } = this.props
      if (model.phone_num && model.phone_num.includes('*')) {
        delete model.phone_num
      }
      if (changeMemberState == false) {
        model.phone_num_disabled = false
      } else {
        delete model.phone_num
      }
      if (phone_num_disabled == true) {
        delete model.phone_num
      }
      let newParams = {}
      if (model.role) {
        newParams.role = model.role
      }
      if (model.title) {
        newParams.title = model.title
      }
      if (!org || !org.id) {
        return api.updateOrgMember(member.id, { ...model }).then(
          json => {
            updateUsersAction.success(json)
              this.props.updateCallBack && this.props.updateCallBack()
            json.json.entities.orgUsers = json.json.entities.users
            this.props.orgUserActions.success(json)
            successAction({ message: '编辑员工成功' })
            reLoad && reLoad()
            KBPopover.close()
            return true
          },
          error => {
            this.props.orgUserActions.failure(error)
          }
        )
      }
      return api
        .updateOrgMember(member.id, { ...model, organization_id: org.id })
        .then(
          json => {
            updateUsersAction.success(json)
            this.props.updateCallBack && this.props.updateCallBack()
            json.json.entities.orgUsers = json.json.entities.users
            this.props.orgUserActions.success(json)
            successAction({ message: '编辑员工成功' })
            reLoad && reLoad()
            KBPopover.close()
            return true
          },
          error => {
            this.props.orgUserActions.failure(error)
          }
        )
    }
    model.organization_id = org && org.id
    return api
      .createOrgMember(model)
      .then(
        json => {
          this.props.createUsersAction.success(json)
          this.props.onOrgMemberCreated && this.props.onOrgMemberCreated(json)
          json.json.entities.orgUsers = json.json.entities.users
          this.props.orgUserActions.success(json)
          successAction({ message: '添加员工成功' })
          callback && callback()
          KBPopover.close()
        },
        error => {
          this.props.orgUserActions.failure(error)
        }
      )
      .finally(() => {
        loadOrganizationInfo && loadOrganizationInfo()
      })
  },

  changePhoneNumDisabled() {
    this.setState({
      phone_num_disabled: false,
      changeMemberState: false
    })
  },

  changePhoneNumAble() {
    const { member, updateUsersAction } = this.props
    api.updateOrganizationMemberPhone(member.id).then(json => {
      updateUsersAction.success(json)
      this.setState({
        phone_num_disabled: true
      })
    })
  },

  setMemberState() {
    this.setState({
      changeMemberState: false
    })
  },

  render() {
    const {
      fields: {
        name,
        gender,
        nickname,
        skill_list,
        wechat,
        birth,
        birth_year,
        birth_month,
        birth_day,
        idcard_type,
        identity_number,
        phone_num,
        phone_num1,
        phone_num2,
        email,
        title,
        role,
        station,
        birthday,
        birthplace,
        interest_list,
        notes,
        value,
        canUsePoint,
        lock_permission_end_date
      },
      error,
      submitting,
      handleSubmit,
      member,
      loc_id
    } = this.props
    const { type, org } = this.props
    const { phone_num_disabled, changeMemberState } = this.state
    const arrayType = MEBMER_GENDERS_FORM

    var defaultClass = {
      className: 'kb-form-group kb-form-c18 kb-form-alone'
    }

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }
    const section = ORG_ROLES_FORM
    const hint = ADD_ORGANIZATION_MEMBER

    const isOrg = org && Object.keys(org).length

    let needFillEmail = false;
    if(loc_id === undefined) {
      // 空间层面
      needFillEmail = (member.organizations || []).some(item => {
        return (item.roles || []).some(i => i !== 'employee')
      })
    } else {
      // 公司层面
      needFillEmail = role.value != 'employee'
    }

    console.log('props', this.props)

    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.createOrganizationMemberForm)}
        fields={this.props.fields}
      >
        <header className="kb-form-header">
          <h2 className="header-title">
            {this.props.type === 'update' ? '编辑会员' : '添加新会员'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        {this.props.error && this.props.error.message ? (
          <KBFormError error={this.props.error} />
        ) : (
          ''
        )}
        <div className="kb-form-container">
          <div className="kb-row kb-div-66">
            <KBInput
              {...halfClass}
              title="姓名"
              isRequired={true}
              field={name}
              fistFoucs={true}
            />
            <KBInput
              {...halfClass}
              title="花名"
              titleClass="label-rt"
              field={nickname}
            />
          </div>
          {isOrg ? (
            <div className="kb-row">
              <KBRadio
                {...defaultClass}
                inClassName="kb-from-initial"
                divClassName="kb-from-radio"
                hint={hint.principal}
                isRequired={true}
                rightLabel="label-rt"
                title="职责"
                arrayType={section}
                name="role"
                field={role}
              />
            </div>
          ) : null}
          {type == 'update' ? (
            phone_num_disabled ? (
              <div className="kb-row kb-form-66">
                <div className="kb-form-group kb-div-210">
                  <label>电话号</label>
                  <div
                    style={{
                      height: 36,
                      lineHeight: '36px',
                      paddingLeft: '15px'
                    }}
                  >
                    <span style={{ color: '#333' }}>
                      该会员电话号码已经挂失
                    </span>
                  </div>
                </div>
                <div className="kb-form-group kb-div-210">
                  <label>&nbsp;</label>
                  <div>
                    <a
                      className="bordered-btn f-left"
                      onClick={this.changePhoneNumDisabled}
                    >
                      添加新号码
                    </a>
                  </div>
                </div>
              </div>
            ) : changeMemberState ? (
              <div className="kb-row kb-form-84">
                <div className="kb-form-group kb-div-210">
                  <label>电话号</label>
                  <div
                    style={{
                      height: 36,
                      lineHeight: '36px',
                      paddingLeft: '10px'
                    }}
                  >
                    <span style={{ color: '#333' }}>{phone_num.value}</span>
                  </div>
                </div>
                <div className="kb-form-group kb-div-210">
                  <label>&nbsp;</label>
                  <div className="kb-form-group kb-div-48">
                    <a
                      className="bordered-btn m-none"
                      onClick={this.setMemberState}
                    >
                      更改
                    </a>
                    <a
                      className="c-btn-warning m-left-sm"
                      onClick={this.changePhoneNumAble}
                    >
                      挂失
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="kb-row kb-form-93">
                <KBInput
                  type="text"
                  className="kb-form-group kb-div-111"
                  title="电话"
                  field={phone_num}
                />
                <div className="kb-form-group kb-div-111">
                  <label>&nbsp;</label>
                  <div>
                    <a
                      className="c-btn-warning f-left"
                      onClick={this.changePhoneNumAble}
                    >
                      挂失
                    </a>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="kb-row">
              <KBInput
                {...defaultClass}
                isRequired={true}
                title="手机号"
                type="text"
                field={phone_num}
              />
            </div>
          )}
          {/* <div className="kb-row kb-form-66">
            <KBInput
              {...halfClass}
              title="手机号1"
              type="text"
              field={phone_num1}
            />
            <KBInput
              {...halfClass}
              title="手机号2"
              type="text"
              field={phone_num2}
              titleClass="label-rt"
            />
          </div> */}
          <div className="kb-row">
            <KBInput
              {...defaultClass}
              title="邮箱"
              isRequired={needFillEmail}
              field={email}
            />
          </div>
          <div className="kb-row">
            <KBDateInput
              defaultYear={new Date().getFullYear()}
              defaultMonth={new Date().getMonth()}
              defaultDay={new Date().getDate()}
              isEmpty={true}
              {...defaultClass}
              field={lock_permission_end_date}
              // value={lock_permission_end_date.value}
              title="门禁权限截止日期"
              isRequired
              disabledDays={DateUtils.isPastDay}
            />
          </div>
          {
            org && org.id && (
              <div className="kb-row">
                <KBRadio
                  {...defaultClass}
                  inClassName="kb-from-initial"
                  divClassName="kb-from-radio"
                  rightLabel="label-rt"
                  title="是否可用办公券"
                  arrayType={[
                    {value: true, title: '是'},
                    {value: false, title: '否'}
                  ]}
                  field={canUsePoint}
                />
              </div>
            )
          }
          {/* <div className="kb-row">
            <KBRadio
              {...defaultClass}
              inClassName="kb-from-initial"
              divClassName="kb-from-radio"
              rightLabel="label-rt"
              title="性别"
              arrayType={arrayType}
              name="sex"
              field={gender}
            />
          </div> */}
          <div className="kb-row">
            <KBInput {...defaultClass} title="职务" field={title} />
          </div>
          {/* <div className="kb-row">
            <KBDateInput
              defaultYear={2000}
              currentYear={new Date().getFullYear()}
              isEmpty={true}
              {...defaultClass}
              field={birth}
              value={birth.value}
              title="出生日期"
              ref="birthDate"
            />
          </div>
          <div className="kb-row">
            <KBInput {...defaultClass} title="出生地" field={birthplace} />
          </div>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="证件类型"
              field={idcard_type}
              arrayData={DOCUMENT_TYPE}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="证件号"
              field={identity_number}
            />
          </div>
          <div className="kb-row">
            <KBInput {...defaultClass} title="微信号" field={wechat} />
          </div> */}
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={notes}
              title="备注"
            />
          </div>
          {/*<div className='kb-row'>
            <KBUserInput selectedUsers={ defaultSkills } title='技能' placeholder='选择技能' className='kb-form-group kb-form-c18 kb-form-alone' field={ skill_list } multi={ true } users={ ORG_MEMBER_SKILLS } disabled={ true }/>
          </div>
          <div className='kb-row' style={{ paddingBottom: 20 }}>
            <KBInput {...defaultClass} title='兴趣爱好' field={interest_list} disabled={ true } inputClass='kb-input-disabled'/>
          </div>*/}
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

OrganizationMemberForm.PropTypes = {
  loc_id: PropTypes.number.isRequired
}

function mapStateToProps(state, ownProps) {
  const { member, initialValues, org } = ownProps
  console.log(111, member);
  let canUsePoint = false;
  let lock_permission_end_date = ''
  if(member && member.organizations && member.organizations.length && ownProps.org && ownProps.org.id) {
    // canUsePoint = member.organizations[0].organization_user.point_priviledge_enabled
    const orgMsg = member.organizations.find(i => i.id == ownProps.org.id)
    if(orgMsg) {
      canUsePoint = orgMsg.organization_user.point_priviledge_enabled
      lock_permission_end_date = orgMsg.organization_user.lock_permission_end_date
    }
  }
  if(!member && org && org.id) {
    lock_permission_end_date = org.subscription_latest_expire_date || ''
  }

  return {
    initialValues: {
      ...initialValues,
      canUsePoint,
      lock_permission_end_date
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createUsersAction: bindActionCreators(usersActions.create, dispatch),
    updateUsersAction: bindActionCreators(usersActions.update, dispatch),
    orgUserActions: bindActionCreators(orgUserActions.update, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = (values, props) => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入新会员姓名'
  }

  if (valid.required(values.lock_permission_end_date)) {
    errors.lock_permission_end_date = '请选择门禁权限截止日期'
  }

  if (values.name && values.name.length > 50) {
    errors.name = '新会员姓名字符过长'
  }

  if (values.nickname && values.nickname.length > 8) {
    errors.nickname = '新会员花名字符过长'
  }

  if (
    valid.required(values.phone_num) ||
    (values.phone_num && values.phone_num.length > 15)
  ) {
    errors.phone_num = '请输入正确的新会员电话'
  }

  if (values.phone_num1 && valid.commonPhoneNumber(values.phone_num1)) {
    errors.phone_num1 = '请输入正确的电话号码'
  }

  if (values.phone_num2 && valid.commonPhoneNumber(values.phone_num2)) {
    errors.phone_num2 = '请输入正确的电话号码'
  }

  //if (valid.required(values.title)) {
  //  errors.title = '请输入正确的新员工职务'
  //}

  // if (valid.required(values.role)) {
  //   errors.role = '请输入新会员职责'
  // }

  if(props.loc_id === undefined) {
    // 空间层面编辑
    const needFillEmail = (props.member.organizations || []).some(item => {
      return (item.roles || []).some(i => i !== 'employee')
    })
    if(needFillEmail && valid.required(values.email)) {
      errors.email = '会员为某家公司负责人或财务负责人时必须填写邮箱'
    }
  } else {
    // 公司层面点击编辑
    if (values.role != 'employee' && valid.required(values.email)) {
      errors.email = '会员为公司负责人或财务负责人时必须填写邮箱'
    }
  }

  // if(moment(values.birth).format('YYYY') == moment(new Date()).format('YYYY')){
  //   errors.birth = '出生年份必须小于当前年份'
  // }
  // if (valid.required(values.station)) {
  //   errors.station = '请输入新员工工位号'
  // }
  return errors
}

const formConfig = {
  form: 'OrganizationMemberForm',
  fields: [
    'name',
    'gender',
    'notes',
    'nickname',
    'birth',
    'birth_year',
    'birth_month',
    'birth_day',
    'idcard_type',
    'wechat',
    'identity_number',
    'phone_num',
    'phone_num1',
    'phone_num2',
    'email',
    'title',
    'role',
    'birthday',
    'birthplace',
    'experience',
    'value',
    'canUsePoint',
    'lock_permission_end_date'
  ],
  validate: validate,
  touchOnBlur: false,
  initialValues: {
    gender: 'male'
  }
}

OrganizationMemberForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrganizationMemberForm)

export default OrganizationMemberForm
